/**************************************************
 * Nombre:       PaginaConstruccion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import logo from '../Recursos/logo_blanco.svg'
import Countdown from 'react-countdown';

const PaginaConstruccion = () => {
    const fechaDespliegue = new Date(2024, 10, 25).getTime()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{minHeight: '100vh', backgroundColor: '#00413B'}}
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={4} sm={4} xs={8} sx={{justifyContent: 'center'}}>
                    <img src={logo} width={'100%'} height={'auto'} title={'Logo Buraz'} alt={'Logo Buraz'}/>
                </Grid>
                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 5}}>
                    <Typography sx={{fontSize: 24, fontWeight: 600, color: '#fff'}}>
                        <Countdown date={fechaDespliegue}/>
                    </Typography>

                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 0}}>
                    <Typography sx={{fontSize: 24, fontWeight: 400, color: '#fff'}}>
                        Muy pronto estaremos contigo
                    </Typography>

                </Grid>


            </Grid>


        </Grid>
    )

}
export default PaginaConstruccion    