import './App.css';
import {Grid} from "@mui/material";
import PaginaConstruccion from "./PaginaConstruccion/PaginaConstruccion";

function App() {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <PaginaConstruccion/>

        </Grid>
    );
}

export default App;
